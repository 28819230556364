var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":_vm.store,"resource":"/customer/wallets","reloadable":"","enterable":"","noFilter":""},on:{"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id}})],1)]}},{key:"wallet",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageWallet',{attrs:{"data":item}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"amount_available_for_payment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount_available_for_payment,"currency":item.currency_id}})],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at,"small":""}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }